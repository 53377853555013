<template>
  <Loader :show-instantly="showInstantly" />
</template>
<script setup lang="ts">
defineProps({
  showInstantly: {
    type: Boolean,
    required: false,
    default: false,
  },
});
const Loader = defineAsyncComponent(() => {
  if (useSiteIdent() === SiteIdent.default) {
    return import('./vdv/page-content.vue');
  } else {
    return import('./minilu/page-content.vue');
  }
});
</script>
